// Validation FDIH 07:00
export function GC_FDIH_PS_0700_TBK(value) {
    return (value >= 5 && value <= 40) || value == 9999;
}

export function GC_FDIH_PS_0700_TBB(value) {
    return (value >= 5 && value <= 35) || value == 9999;
}

export function GC_FDIH_0700_ANGIN_COUNTER_BACA(value) {
    return value >= 0 && value <= GC.counter_0c5_0700.max;
}

export function GC_FDIH_0700_ANGIN_ARAH(value) {
    // var validate = {gross_check : 0, range_check : 0}
    // if(value > 10 && value < 40) {
    //   range_check = 1
    // }

    // if(value > 0 && value < 360) {
    //   gross_check = 1
    // }

    // if(validate.gross_check == 1 && validate.range_check == 1 ) {

    // }

    return (value >= 0 && value <= 360) || value == 9999;
}

export function GC_FDIH_0700_ANGIN_KECEPATAN(value) {
    return (value >= 0 && value <= 50) || value == 9999;
}

export function GC_FDIH_0700_OP_SELISIH(value) {
    return (value >= -300 && value <= 50) || value == 9999;
}
export function GC_FDIH_0700_OP_T_AIR_MAX(value) {
    return (value >= 10 && value <= 45) || value == 9999;
}
export function GC_FDIH_0700_OP_CH_DIUKUR(value) {
    return (value >= 0 && value <= 300) || value == 9999 || value == 8888;
}
export function GC_FDIH_0700_OP_T_AIR_MIN(value) {
    return (value >= GC.t_min_0700.min && value <= GC.t_min_0700.max) || value == GC.t_min_0700.specialValue[0];
}

export function GC_FDIH_0700_GUNBELLANI1(value) {
    // return (value >= 0 && value <= 40) || value == 9999;
    return (value >= GC.ha_gunbellani_1.min && value <= GC.ha_gunbellani_1.max) || value == GC.ha_gunbellani_1.specialValue[0];
}

export function GC_FDIH_0700_GUNBELLANI2(value) {
    // return (value >= 0 && value <= 15) || value == 9999;
    return (value >= GC.ha_gunbellani_2.min && value <= GC.ha_gunbellani_2.max) || value == GC.ha_gunbellani_2.specialValue[0];
}

export function GC_FDIH_0700_HA_DIFF_GUNBELLANI(value) {
    return (value >= GC.ha_diff_gunbellani.min && value <= GC.ha_diff_gunbellani.max) || value == GC.ha_diff_gunbellani.specialValue[0];
}
export function GC_FDIH_0700_SUHU_RUMPUT(value) {
    return (value >= 5 && value <= 35) || value == 9999 || value == "";
}
export function GC_FDIH_0700_SUHU_RUMPUT_TRESET(value) {
    return (value >= 5 && value <= 45) || value == 9999 || value == "";
}

// Validation FDIH 07:30
export function GC_FDIH_PS_0730_TBK(value) {
    return (value >= 5 && value <= 40) || value == 9999;
}
export function GC_FDIH_PS_0730_TBB(value) {
    return (value >= 5 && value <= 35) || value == 9999;
}
export function GC_FDIH_0730_ANGIN_COUNTER_BACA(value) {
    return value >= 0 && value <= GC.counter_0c5_0730.max;
}
export function GC_FDIH_0730_ANGIN_KEC_PERAMATAN(value) {
    return (value >= 0 && value <= 50) || value == 9999;
}
export function GC_FDIH_0730_OP_SELISIH(value) {
    return (value >= -150 && value <= 20) || value == 9999;
}
export function GC_FDIH_0730_OP_T_AIR_MAX(value) {
    return (value >= 10 && value <= 45) || value == 9999;
}
export function GC_FDIH_0730_OP_T_AIR_MIN(value) {
    return (value >= GC.t_air_min_0730.min && value <= GC.t_air_min_0730.max) || value == GC.t_air_min_0730.specialValue[0];
}
export function GC_FDIH_0730_OP_CH_DIUKUR(value) {
    return (value >= 0 && value <= 300) || value == 9999 || value == 8888;
}
export function GC_FDIH_0730_SUHU_TANAH(value) {
    return (value >= 5 && value <= GC.ttg_0cm_0730.max) || value == 9999;
}
export function GC_FDIH_0730_50CM_SUHU_TANAH(value) {
    return (value >= 5 && value <= GC.ttg_0cm_0730.max) || value == 9999 || value === "" || value === null;
}
export function GC_FDIH_0730_100CM_SUHU_TANAH(value) {
    return (value >= 5 && value <= GC.ttg_0cm_0730.max) || value == 9999 || value === "" || value === null;
}
// Validation FDIH 13:00
export function GC_FDIH_PS_1300_TBK(value) {
    return (value >= 5 && value <= 40) || value == 9999;
}
export function GC_FDIH_PS_1300_TBB(value) {
    return (value >= 5 && value <= 35) || value == 9999;
}
export function GC_FDIH_1300_ANGIN_COUNTER_BACA(value) {
    return value >= 0 && value <= GC.counter_4_1300.max;
}
export function GC_FDIH_1300_SUHU_MIN(value) {
    return (value >= 5 && value <= 30) || value == 9999;
}

// Validation FDIH 13:30
export function GC_FDIH_PS_1330_TBK(value) {
    return (value >= 5 && value <= 40) || value == 9999;
}
export function GC_FDIH_PS_1330_TBB(value) {
    return (value >= 5 && value <= 35) || value == 9999;
}
export function GC_FDIH_1330_ANGIN_COUNTER_BACA(value) {
    return value >= 0 && value <= GC.counter_0c5_1330.max;
}
export function GC_FDIH_1330_ANGIN_KEC_PERAMATAN(value) {
    return (value >= 0 && value <= 50) || value == 9999;
}
export function GC_FDIH_1330_OP_SELISIH(value) {
    return (value >= -150 && value <= 20) || value == 9999;
}
export function GC_FDIH_1330_OP_T_AIR_MAX(value) {
    return (value >= 10 && value <= 45) || value == 9999;
}
export function GC_FDIH_1330_OP_T_AIR_MIN(value) {
    return (value >= GC.t_air_min_1330.min && value <= GC.t_air_min_1330.max) || value == GC.t_air_min_1330.specialValue[0];
}
export function GC_FDIH_1330_OP_CH_DIUKUR(value) {
    return (value >= 0 && value <= 300) || value == 9999 || value == 8888;
}
export function GC_FDIH_1330_SUHU_TANAH(value) {
    return (value >= 5 && value <= GC.ttg_0cm_1330.max) || value == 9999;
}
export function GC_FDIH_1330_50CM_SUHU_TANAH(value) {
    return (value >= 5 && value <= GC.ttg_0cm_1330.max) || value == 9999 || value === "" || value === null;
}
export function GC_FDIH_1330_100CM_SUHU_TANAH(value) {
    return (value >= 5 && value <= GC.ttg_0cm_1330.max) || value == 9999 || value === "" || value === null;
}

// Validation FDIH 14:00
export function GC_FDIH_PS_1400_TBK(value) {
    return (value >= 5 && value <= 40) || value == 9999;
}
export function GC_FDIH_PS_1400_TBB(value) {
    return (value >= 5 && value <= 35) || value == 9999;
}
export function GC_FDIH_1400_ANGIN_COUNTER_BACA(value) {
    return value >= 0 && value <= GC.counter_7_1400.max;
}
export function GC_FDIH_1400_ANGIN_ARAH(value) {
    return (value >= 0 && value <= 360) || value == 9999;
}

export function GC_FDIH_1400_ANGIN_KECEPATAN(value) {
    return (value >= 0 && value <= 50) || value == 9999;
}
export function GC_FDIH_SM_1400_Tmax(value) {
    return (value >= 5 && value <= 45) || value == 9999;
}
export function GC_FDIH_SM_1400_Treset(value) {
    return (value >= 5 && value <= 45) || value == 9999;
}

// Validation FDIH 17:00
export function GC_LYSIMETER_SIRAM(value) {
    return (value >= 0 && value <= 11) || value == 9999;
}
export function GC_LYSIMETER_PERKOLASI(value) {
    return (value >= 0 && value <= 150) || value == 9999;
}
export function GC_LYSIMETER_EVA(value) {
    return value == 9999;
}
export function GC_LYSIMETER_CR_HUJAN(value) {
    return (value >= 0 && value <= 100) || value == 9999;
}

// Validation FDIH 17:30
export function GC_FDIH_PS_1730_TBK(value) {
    return (value >= 5 && value <= 40) || value == 9999;
}
export function GC_FDIH_PS_1730_TBB(value) {
    return (value >= 5 && value <= 35) || value == 9999;
}
export function GC_FDIH_1730_ANGIN_COUNTER_BACA(value) {
    return value >= 0 && value <= GC.counter_0c5_1730.max;
}
export function GC_FDIH_1730_ANGIN_KEC_PERAMATAN(value) {
    return (value >= 0 && value <= 50) || value == 9999;
}
export function GC_FDIH_1730_OP_SELISIH(value) {
    return (value >= -150 && value <= 20) || value == 9999;
}
export function GC_FDIH_1730_OP_T_AIR_MAX(value) {
    return (value >= 10 && value <= 45) || value == 9999;
}
export function GC_FDIH_1730_OP_T_AIR_MIN(value) {
    return (value >= GC.t_air_min_1730.min && value <= GC.t_air_min_1730.max) || value == GC.t_air_min_1730.specialValue;
}
export function GC_FDIH_1730_OP_CH_DIUKUR(value) {
    return (value >= 0 && value <= 300) || value == 9999 || value == 8888;
}
export function GC_FDIH_1730_SUHU_TANAH(value) {
    return (value >= 5 && value <= GC.ttg_0cm_1730.max) || value == 9999;
}

// Validation FDIH 18:00
export function GC_FDIH_PS_1800_TBK(value) {
    return (value >= 5 && value <= 40) || value == 9999;
}
export function GC_FDIH_PS_1800_TBB(value) {
    return (value >= 5 && value <= 35) || value == 9999;
}
export function GC_FDIH_1800_ANGIN_COUNTER_BACA(value) {
    return value >= 0 && value <= GC.counter_4_1800.max;
}
export function GC_FDIH_SM_1800_Tmax(value) {
    return (value >= 10 && value <= 45) || value == 9999;
}
export function GC_FDIH_SM_1800_Treset(value) {
    return (value >= 10 && value <= 45) || value == 9999;
}
export function GC_FDIH_1800_QFE(value) {
    return (value >= 700 && value <= 1100) || value == 9999;
}

// RANGE CHECK FDIH
const RC = {
    ws_0700: { min: 0, max: 30, special_value: [9999] },
    op_diff_baca_0700: { min: -100, max: 15, special_value: [9999] },
    t_max_0700: { min: 15, max: 35, special_value: [9999] },
    t_min_0700: { min: 10, max: 30, special_value: [9999] },
    rr_0700: { min: 0, max: 250, special_value: [9999] },
    ha_gunbellani_1: { min: 0, max: 10, special_value: [9999] },
    ha_gunbellani_2: { min: 0, max: 35, special_value: [9999] },
    ha_diff_gunbellani: { min: 0, max: 30, special_value: [9999] },
    op_diff_baca_0730: { min: -110, max: 15, special_value: [9999] },
    t_air_max_0730: { min: 15, max: 35, special_value: [9999] },
    t_air_min_0730: { min: 10, max: 30, special_value: [9999] },
    rr_0730: { min: 0, max: 250, special_value: [9999] },
    op_diff_baca_1330: { min: 0, max: 250, special_value: [9999] },
    t_air_max_1330: { min: 15, max: 35, special_value: [9999] },
    t_air_min_1330: { min: 10, max: 30, special_value: [9999] },
    rr_1330: { min: 0, max: 250, special_value: [9999] },
    ws_1400: { min: 0, max: 30, special_value: [9999] },
    op_diff_baca_1730: { min: 0, max: 250, special_value: [9999] },
    t_air_max_1730: { min: 15, max: 35, special_value: [9999] },
    t_air_min_1730: { min: 10, max: 30, special_value: [9999] },
    rr_1730: { min: 0, max: 250, special_value: [9999] },

    tn_rumput_0700: { min: 10, max: 30, special_value: [9999] },
    tn_rumput_reset_0700: { min: 10, max: 40, special_value: [9999] },

    //RC suhu tanah 07:30 13:30 & 1730
    ttr_0cm_0730: { min: 10, max: 40, special_value: [9999] },
    ttr_2cm_0730: { min: 10, max: 40, special_value: [9999] },
    ttr_5cm_0730: { min: 10, max: 40, special_value: [9999] },
    ttr_10cm_0730: { min: 10, max: 40, special_value: [9999] },
    ttr_20cm_0730: { min: 10, max: 40, special_value: [9999] },
    ttr_50cm_0730: { min: 10, max: 40, special_value: [9999] },
    ttr_100cm_0730: { min: 10, max: 40, special_value: [9999] },
    ttg_0cm_0730: { min: 10, max: 40, special_value: [9999] },
    ttg_2cm_0730: { min: 10, max: 40, special_value: [9999] },
    ttg_5cm_0730: { min: 10, max: 40, special_value: [9999] },
    ttg_10cm_0730: { min: 10, max: 40, special_value: [9999] },
    ttg_20cm_0730: { min: 10, max: 40, special_value: [9999] },
    ttg_50cm_0730: { min: 10, max: 40, special_value: [9999] },
    ttg_100cm_0730: { min: 10, max: 40, special_value: [9999] },

    ttr_0cm_1330: { min: 10, max: 40, special_value: [9999] },
    ttr_2cm_1330: { min: 10, max: 40, special_value: [9999] },
    ttr_5cm_1330: { min: 10, max: 40, special_value: [9999] },
    ttr_10cm_1330: { min: 10, max: 40, special_value: [9999] },
    ttr_20cm_1330: { min: 10, max: 40, special_value: [9999] },
    ttr_50cm_1330: { min: 10, max: 40, special_value: [9999] },
    ttr_100cm_1330: { min: 10, max: 40, special_value: [9999] },
    ttg_0cm_1330: { min: 10, max: 40, special_value: [9999] },
    ttg_2cm_1330: { min: 10, max: 40, special_value: [9999] },
    ttg_5cm_1330: { min: 10, max: 40, special_value: [9999] },
    ttg_10cm_1330: { min: 10, max: 40, special_value: [9999] },
    ttg_20cm_1330: { min: 10, max: 40, special_value: [9999] },
    ttg_50cm_1330: { min: 10, max: 40, special_value: [9999] },
    ttg_100cm_1330: { min: 10, max: 40, special_value: [9999] },

    ttr_0cm_1730: { min: 10, max: 30, special_value: [9999] },
    ttr_2cm_1730: { min: 10, max: 30, special_value: [9999] },
    ttr_5cm_1730: { min: 10, max: 30, special_value: [9999] },
    ttr_10cm_1730: { min: 10, max: 30, special_value: [9999] },
    ttr_20cm_1730: { min: 10, max: 30, special_value: [9999] },
    ttr_50cm_1730: { min: 10, max: 30, special_value: [9999] },
    ttr_100cm_1730: { min: 10, max: 30, special_value: [9999] },
    ttg_0cm_1730: { min: 10, max: 40, special_value: [9999] },
    ttg_2cm_1730: { min: 10, max: 40, special_value: [9999] },
    ttg_5cm_1730: { min: 10, max: 40, special_value: [9999] },
    ttg_10cm_1730: { min: 10, max: 40, special_value: [9999] },
    ttg_20cm_1730: { min: 10, max: 40, special_value: [9999] },
    ttg_50cm_1730: { min: 10, max: 40, special_value: [9999] },
    ttg_100cm_1730: { min: 10, max: 40, special_value: [9999] },

    //RC 17:00
    s_gundul: { min: 0, max: 10, special_value: [9999] },
    s_komoditi: { min: 0, max: 10, special_value: [9999] },
    s_berumput: { min: 0, max: 10, special_value: [9999] },

    pk_gundul: { min: 0, max: 100, special_value: [9999] },
    pk_komoditi: { min: 0, max: 100, special_value: [9999] },
    pk_berumput: { min: 0, max: 100, special_value: [9999] },

    rr_gundul: { min: 0, max: 100, special_value: [9999] },
    rr_komoditi: { min: 0, max: 100, special_value: [9999] },
    rr_berumput: { min: 0, max: 100, special_value: [9999] },

    //RC 18:00
    t_max_1c2m: { min: 15, max: 40, special_value: [9999] },
    t_max_4m: { min: 15, max: 40, special_value: [9999] },
    t_max_7m: { min: 15, max: 40, special_value: [9999] },
    t_max_10m: { min: 15, max: 40, special_value: [9999] },
    t_reset_1c2m_1800: { min: 15, max: 40, special_value: [9999] },
};

export function RC_FDIH_ROLES(key) {
    if (!RC.hasOwnProperty(key)) {
        return "";
    }
    return RC[key].min + "," + RC[key].max + "," + RC[key].special_value;
}

export function RC_FDIH(value, key) {
    let status = false;
    let message = "";
    let min = RC[key].min;
    let max = RC[key].max;
    let spv = RC[key].special_value;
    if (value >= min && value <= max) {
        status = true;
        message = "";
    } else if (spv && spv.length > 0) {
        status = false;
        message = "Range Check - Batas Bawah " + min + ", Batas Atas " + max + ", Special Value " + spv;
    } else {
        status = false;
        message = "Range Check - Batas Bawah " + min + ", Batas Atas " + max;
    }

    if (spv.length > 0) {
        if (spv[0] == value) {
            status = true;
            message = "";
        }
        if (spv.length > 1) {
            if (spv[1] == value) {
                status = true;
                message = "";
            }
        }
    }
    return { status: status, message: message };
}

const GC = {
    tbk_1c2m_0700: { min: 5, max: 40, specialValue: [9999] },
    tbk_4m_0700: { min: 5, max: 40, specialValue: [9999] },
    tbk_7m_0700: { min: 5, max: 40, specialValue: [9999] },
    tbk_10m_0700: { min: 5, max: 40, specialValue: [9999] },

    tbb_1c2m_0700: { min: 5, max: 35, specialValue: [9999] },
    tbb_4m_0700: { min: 5, max: 35, specialValue: [9999, 8888] },
    tbb_7m_0700: { min: 5, max: 35, specialValue: [9999, 8888] },
    tbb_10m_0700: { min: 5, max: 35, specialValue: [9999, 8888] },

    counter_0c5_0700: { min: 0, max: 999999999, specialValue: [9999] },
    counter_4_0700: { min: 0, max: 999999999, specialValue: [9999] },
    counter_7_0700: { min: 0, max: 999999999, specialValue: [9999] },
    counter_10_0700: { min: 0, max: 999999999, specialValue: [9999] },
    wd_0700: { min: 0, max: 360, specialValue: [9999] },
    ws_0700: { min: 0, max: 50, specialValue: [9999] },

    op_diff_baca_0700: { min: -300, max: 50, specialValue: [9999] },
    t_max_0700: { min: 10, max: 45, specialValue: [9999] },
    t_min_0700: { min: 5, max: 40, specialValue: [9999] },
    rr_0700: { min: 0, max: 300, specialValue: [9999, 8888] },
    ha_gunbellani_2: { min: 0, max: 50, specialValue: [9999] },
    ha_gunbellani_1: { min: 0, max: 50, specialValue: [9999] },
    ha_diff_gunbellani: { min: 0, max: 50, specialValue: [9999] },
    tn_rumput_0700: { min: 5, max: 35, specialValue: [9999] },
    tn_rumput_reset_0700: { min: 5, max: 45, specialValue: [9999] },

    //GC 07:30
    tbk_1c2m_0730: { min: 5, max: 40, specialValue: [9999] },
    tbb_1c2m_0730: { min: 5, max: 35, specialValue: [9999] },
    counter_0c5_0730: { min: 0, max: 999999999, specialValue: [9999] },
    ws_10_0730: { min: 0, max: 50, specialValue: [9999] },
    h_air_diff_0730: { min: -150, max: 20, specialValue: [9999] },
    t_air_max_0730: { min: 10, max: 45, specialValue: [9999] },
    t_air_min_0730: { min: 5, max: 40, specialValue: [9999] },
    rr_0730: { min: 0, max: 300, specialValue: [9999, 8888] },
    tbk_5cm_0730: { min: 5, max: 40, specialValue: [9999] },
    tbk_10cm_0730: { min: 5, max: 40, specialValue: [9999] },
    tbk_20cm_0730: { min: 5, max: 40, specialValue: [9999] },
    tbk_50cm_0730: { min: 5, max: 40, specialValue: [9999] },
    tbk_100cm_0730: { min: 5, max: 40, specialValue: [9999] },
    tbk_150cm_0730: { min: 5, max: 40, specialValue: [9999] },
    tbk_200cm_0730: { min: 5, max: 40, specialValue: [9999] },
    tbb_5cm_0730: { min: 5, max: 35, specialValue: [9999] },
    tbb_10cm_0730: { min: 5, max: 35, specialValue: [9999] },
    tbb_20cm_0730: { min: 5, max: 35, specialValue: [9999] },
    tbb_50cm_0730: { min: 5, max: 35, specialValue: [9999] },
    tbb_100cm_0730: { min: 5, max: 35, specialValue: [9999] },
    tbb_150cm_0730: { min: 5, max: 35, specialValue: [9999] },
    tbb_200cm_0730: { min: 5, max: 35, specialValue: [9999] },

    ttr_0cm_0730: { min: 5, max: 60, specialValue: [9999] },
    ttr_2cm_0730: { min: 5, max: 60, specialValue: [9999] },
    ttr_5cm_0730: { min: 5, max: 60, specialValue: [9999] },
    ttr_10cm_0730: { min: 5, max: 60, specialValue: [9999] },
    ttr_20cm_0730: { min: 5, max: 60, specialValue: [9999] },
    ttr_50cm_0730: { min: 5, max: 60, specialValue: [9999] },
    ttr_100cm_0730: { min: 5, max: 60, specialValue: [9999] },

    ttg_0cm_0730: { min: 5, max: 60, specialValue: [9999] },
    ttg_2cm_0730: { min: 5, max: 60, specialValue: [9999] },
    ttg_5cm_0730: { min: 5, max: 60, specialValue: [9999] },
    ttg_10cm_0730: { min: 5, max: 60, specialValue: [9999] },
    ttg_20cm_0730: { min: 5, max: 60, specialValue: [9999] },
    ttg_50cm_0730: { min: 5, max: 60, specialValue: [9999] },
    ttg_100cm_0730: { min: 5, max: 60, specialValue: [9999] },

    //qc 1330

    tbk_1c2m_1330: { min: 5, max: 40, specialValue: [9999] },
    tbb_1c2m_1330: { min: 5, max: 35, specialValue: [9999] },
    counter_0c5_1330: { min: 0, max: 999999999, specialValue: [9999] },
    ws_10_1330: { min: 0, max: 50, specialValue: [9999] },
    h_air_diff_1330: { min: -150, max: 20, specialValue: [9999] },
    t_air_max_1330: { min: 10, max: 46, specialValue: [9999] },
    t_air_min_1330: { min: 5, max: 45, specialValue: [9999] },
    rr_1330: { min: 0, max: 300, specialValue: [9999, 8888] },
    tbk_5cm_1330: { min: 5, max: 40, specialValue: [9999] },
    tbk_10cm_1330: { min: 5, max: 40, specialValue: [9999] },
    tbk_20cm_1330: { min: 5, max: 40, specialValue: [9999] },
    tbk_50cm_1330: { min: 5, max: 40, specialValue: [9999] },
    tbk_100cm_1330: { min: 5, max: 40, specialValue: [9999] },
    tbk_150cm_1330: { min: 5, max: 40, specialValue: [9999] },
    tbk_200cm_1330: { min: 5, max: 40, specialValue: [9999] },

    tbb_5cm_1330: { min: 5, max: 35, specialValue: [9999] },
    tbb_10cm_1330: { min: 5, max: 35, specialValue: [9999] },
    tbb_20cm_1330: { min: 5, max: 35, specialValue: [9999] },
    tbb_50cm_1330: { min: 5, max: 35, specialValue: [9999] },
    tbb_100cm_1330: { min: 5, max: 35, specialValue: [9999] },
    tbb_150cm_1330: { min: 5, max: 35, specialValue: [9999] },
    tbb_200cm_1330: { min: 5, max: 35, specialValue: [9999] },

    ttr_0cm_1330: { min: 5, max: 60, specialValue: [9999] },
    ttr_2cm_1330: { min: 5, max: 60, specialValue: [9999] },
    ttr_5cm_1330: { min: 5, max: 60, specialValue: [9999] },
    ttr_10cm_1330: { min: 5, max: 60, specialValue: [9999] },
    ttr_20cm_1330: { min: 5, max: 60, specialValue: [9999] },
    ttr_50cm_1330: { min: 5, max: 60, specialValue: [9999] },
    ttr_100cm_1330: { min: 5, max: 60, specialValue: [9999] },

    ttg_0cm_1330: { min: 5, max: 60, specialValue: [9999] },
    ttg_2cm_1330: { min: 5, max: 60, specialValue: [9999] },
    ttg_5cm_1330: { min: 5, max: 60, specialValue: [9999] },
    ttg_10cm_1330: { min: 5, max: 60, specialValue: [9999] },
    ttg_20cm_1330: { min: 5, max: 60, specialValue: [9999] },
    ttg_50cm_1330: { min: 5, max: 60, specialValue: [9999] },
    ttg_100cm_1330: { min: 5, max: 60, specialValue: [9999] },

    //gc 1400

    tbk_1c2m_1400: { min: 5, max: 40, specialValue: [9999] },
    tbb_1c2m_1400: { min: 5, max: 35, specialValue: [9999] },
    rh_1c2m_1400: { min: 0, max: 100, specialValue: [9999] },
    t_min_uji_1400: { min: 5, max: 45, specialValue: [9999] },
    t_reset_uji_1400: { min: 5, max: 45, specialValue: [9999] },
    counter_7_1400: { min: 0, max: 999999999, specialValue: [9999] },
    wd_1400: { min: 0, max: 360, specialValue: [9999] },
    ws_1400: { min: 0, max: 50, specialValue: [9999] },

    //gc 1700

    s_gundul: { min: 0, max: 11, specialValue: [9999] },
    s_komoditi: { min: 0, max: 11, specialValue: [9999] },
    s_berumput: { min: 0, max: 11, specialValue: [9999] },
    pk_gundul: { min: 0, max: 150, specialValue: [9999] },
    pk_komoditi: { min: 0, max: 150, specialValue: [9999] },
    pk_berumput: { min: 0, max: 150, specialValue: [9999] },
    rr_gundul: { min: 0, max: 100, specialValue: [9999] },
    rr_komoditi: { min: 0, max: 100, specialValue: [9999] },
    rr_berumput: { min: 0, max: 100, specialValue: [9999] },

    //gc 1730
    tbk_1c2m_1730: { min: 5, max: 40, specialValue: [9999] },
    tbb_1c2m_1730: { min: 5, max: 35, specialValue: [9999] },
    counter_0c5_1730: { min: 0, max: 999999999, specialValue: [9999] },
    ws_10_1730: { min: 0, max: 50, specialValue: [9999] },
    h_air_diff_1730: { min: -150, max: 20, specialValue: [9999] },
    t_air_max_1730: { min: 10, max: 45, specialValue: [9999] },
    t_air_min_1730: { min: 5, max: 40, specialValue: [9999] },
    rr_1730: { min: 0, max: 300, specialValue: [9999, 8888] },
    tbk_5cm_1730: { min: 5, max: 40, specialValue: [9999] },
    tbk_10cm_1730: { min: 5, max: 40, specialValue: [9999] },
    tbk_20cm_1730: { min: 5, max: 40, specialValue: [9999] },
    tbk_50cm_1730: { min: 5, max: 40, specialValue: [9999] },
    tbk_100cm_1730: { min: 5, max: 40, specialValue: [9999] },
    tbk_150cm_1730: { min: 5, max: 40, specialValue: [9999] },
    tbk_200cm_1730: { min: 5, max: 40, specialValue: [9999] },
    tbb_5cm_1730: { min: 5, max: 35, specialValue: [9999] },
    tbb_10cm_1730: { min: 5, max: 35, specialValue: [9999] },
    tbb_20cm_1730: { min: 5, max: 35, specialValue: [9999] },
    tbb_50cm_1730: { min: 5, max: 35, specialValue: [9999] },
    tbb_100cm_1730: { min: 5, max: 35, specialValue: [9999] },
    tbb_150cm_1730: { min: 5, max: 35, specialValue: [9999] },
    tbb_200cm_1730: { min: 5, max: 35, specialValue: [9999] },
    ttr_0cm_1730: { min: 5, max: 60, specialValue: [9999] },
    ttr_2cm_1730: { min: 5, max: 60, specialValue: [9999] },
    ttr_5cm_1730: { min: 5, max: 60, specialValue: [9999] },
    ttr_10cm_1730: { min: 5, max: 60, specialValue: [9999] },
    ttr_20cm_1730: { min: 5, max: 60, specialValue: [9999] },
    ttr_50cm_1730: { min: 5, max: 60, specialValue: [9999] },
    ttr_100cm_1730: { min: 5, max: 60, specialValue: [9999] },
    ttg_0cm_1730: { min: 5, max: 60, specialValue: [9999] },
    ttg_2cm_1730: { min: 5, max: 60, specialValue: [9999] },
    ttg_5cm_1730: { min: 5, max: 60, specialValue: [9999] },
    ttg_10cm_1730: { min: 5, max: 60, specialValue: [9999] },
    ttg_20cm_1730: { min: 5, max: 60, specialValue: [9999] },
    ttg_50cm_1730: { min: 5, max: 60, specialValue: [9999] },
    ttg_100cm_1730: { min: 5, max: 60, specialValue: [9999] },

    //QC 13:00
    tbk_1c2m_1300: { min: 5, max: 40, specialValue: [9999] },
    tbk_4m_1300: { min: 5, max: 40, specialValue: [9999] },
    tbk_7m_1300: { min: 5, max: 40, specialValue: [9999] },
    tbk_10m_1300: { min: 5, max: 40, specialValue: [9999] },
    tbb_1c2m_1300: { min: 5, max: 35, specialValue: [9999] },
    tbb_4m_1300: { min: 5, max: 35, specialValue: [9999] },
    tbb_7m_1300: { min: 5, max: 35, specialValue: [9999] },
    tbb_10m_1300: { min: 5, max: 35, specialValue: [9999] },
    rh_1c2m_1300: { min: 0, max: 100, specialValue: [9999] },
    rh_4m_1300: { min: 0, max: 100, specialValue: [9999] },
    rh_7m_1300: { min: 0, max: 100, specialValue: [9999] },
    rh_10m_1300: { min: 0, max: 100, specialValue: [9999] },
    counter_4_1300: { min: 0, max: 999999999, specialValue: [9999] },
    counter_7_1300: { min: 0, max: 999999999, specialValue: [9999] },
    counter_10_1300: { min: 0, max: 999999999, specialValue: [9999] },
    t_min_1c2m: { min: 5, max: 30, specialValue: [9999] },
    t_min_4m: { min: 5, max: 35, specialValue: [9999] },
    t_min_7m: { min: 5, max: 35, specialValue: [9999] },
    t_min_10m: { min: 5, max: 35, specialValue: [9999] },

    //gc 1800

    tbk_1c2m_1800: { min: 5, max: 40, specialValue: [9999] },
    tbk_4m_1800: { min: 5, max: 40, specialValue: [9999] },
    tbk_7m_1800: { min: 5, max: 40, specialValue: [9999] },
    tbk_10m_1800: { min: 5, max: 40, specialValue: [9999] },
    tbb_1c2m_1800: { min: 5, max: 35, specialValue: [9999] },
    tbb_4m_1800: { min: 5, max: 35, specialValue: [9999] },
    tbb_7m_1800: { min: 5, max: 35, specialValue: [9999] },
    tbb_10m_1800: { min: 5, max: 35, specialValue: [9999] },
    rh_1c2m_1800: { min: 0, max: 100, specialValue: [9999] },
    rh_4m_1800: { min: 0, max: 100, specialValue: [9999] },
    rh_7m_1800: { min: 0, max: 100, specialValue: [9999] },
    rh_10m_1800: { min: 0, max: 100, specialValue: [9999] },
    counter_4_1800: { min: 0, max: 999999999, specialValue: [9999] },
    counter_7_1800: { min: 0, max: 999999999, specialValue: [9999] },
    counter_7_2_1800: { min: 0, max: 999999999, specialValue: [9999] },
    counter_10_1800: { min: 0, max: 999999999, specialValue: [9999] },
    t_max_1c2m: { min: 10, max: 45, specialValue: [9999] },
    t_max_4m: { min: 10, max: 45, specialValue: [9999] },
    t_max_7m: { min: 10, max: 45, specialValue: [9999] },
    t_max_10m: { min: 10, max: 45, specialValue: [9999] },
    t_reset_1c2m_1800: { min: 10, max: 45, specialValue: [9999] },
    pp_qfe_0000: { min: 700, max: 1100, specialValue: [9999] },
};

export function GC_FDIH(key) {
    let status = false;
    let message = "";
    let min = GC[key].min;
    let max = GC[key].max;
    let spv = GC[key].specialValue;
    // if (value >= min && value <= max) {
    //   status = true;
    //   message = "";
    // } else {
    //   status = false;
    //   message = "Gross Check - Batas Bawah " + min + ", Batas Atas " + max;
    // }
    status = false;
    message = "Gross Check - Batas Bawah " + min + ", Batas Atas " + max + ", Special Value " + spv;
    return { status: status, message: message };
}

// KONSISTENSI CHECK FDIH

export function KC_FDIH_PS_0700_TBK(tbk, tbb) {
    return tbk >= tbb;
}

export function KC_FDIH_PS_0700_TBB(tbb, tbk) {
    return tbk >= tbb;
}
